import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { of } from 'rxjs';

export const RoleGuard: CanActivateFn = (route, state) => {
  const authService = inject(MsalService);
  const router = inject(Router);
  // extract the role from the route data
  const requiredRoles = route.data['roles'] as Array<string>;
  // get active account
  const account = authService.instance.getActiveAccount();
  if (!account) {
    router.navigate(['/login']);
    return of(false);
  }
  // check if the account has the required role
  const userRoles = account.idTokenClaims?.roles ?? [];
  // console.log('User roles:', userRoles);

  const hasRequiredRole = requiredRoles.some((role) =>
    userRoles.includes(role),
  );
  if (!hasRequiredRole) {
    router.navigate(['/discover']);
    return of(false);
  }
  return of(true);
};
