<div class="min-h-0 h-screen w-[calc(100vw-250px)] p-8">
  <div class="card flex flex-col py-4 text-slate-50 mx-auto p-8">
    <h2 class="title-font mb-1 text-lg font-medium">Contact</h2>
    <p class="mb-5 leading-relaxed text-slate-400">
      If you had any issues or you liked our product, please share with us!
    </p>
    <form [formGroup]="this.contactForm" (ngSubmit)="submitForm()">
      <div class="mb-4 grid sm:grid-cols-3 gap-4">
        <div class="grid">
          <label for="title" class="required text-sm leading-7">Title</label>
          <input
            formControlName="title"
            type="text"
            id="title"
            name="title"
            class="input w-full rounded bg-white bg-opacity-5 text-slate-300 text-base leading-8 outline-none transition-colors duration-200 ease-in-out focus:ring-secondary focus:ring-1"
          />
          <!-- <div *ngIf="title.invalid && (title.dirty || title.touched)" class="text-red-600">
            <span *ngIf="title.errors?.['required']" class="text-xs">Title is required</span>
          </div>
           -->
        </div>
        <div class="grid">
          <label for="email" class="required text-sm leading-7">Email</label>
          <input
            formControlName="email"
            type="email"
            id="email"
            name="email"
            class="input w-full rounded bg-white bg-opacity-5 text-slate-300 text-base leading-8 outline-none transition-colors duration-200 ease-in-out focus:ring-secondary focus:ring-1"
          />
        </div>
        <div class="grid custom-select relative">
          <label for="type" class="required text-sm leading-7">Type</label>
          <select
            formControlName="type"
            class="select w-full rounded-md bg-white bg-opacity-5 focus:ring-secondary focus:ring-1"
          >
            @for (type of this.types; track type) {
              <option
                [value]="type"
                class="bg-[#445c74] border m-2 hover:bg-primary"
              >
                <span class="text-slate-50"> {{ type }} </span>
              </option>
            }
          </select>
        </div>
      </div>
      <div class="mb-4">
        <label for="message" class="required text-sm leading-7">Message</label>
        <textarea
          formControlName="message"
          id="message"
          name="message"
          class="h-32 w-full resize-none rounded bg-white bg-opacity-5 text-slate-300 py-1 px-3 text-base leading-6 outline-none transition-colors duration-200 ease-in-out focus:ring-secondary focus:ring-1"
        ></textarea>
      </div>
      <button
        type="submit"
        class="rounded border-0 bg-secondring-secondary py-2 px-6 text-lg text-white hover:bg-opacity-85 focus:outline-none"
      >
        Send
      </button>
      <div
        *ngIf="this.submitted && this.contactForm.invalid"
        class="alert mt-2 text-slate-50 bg-accent bg-opacity-50 w-fit px-2 py-1 rounded-lg border-0 flex items-center"
      >
        <span *ngIf="this.requiredErrors">
          Please fill out all form fields.
        </span>

        <span *ngIf="this.emailError">
          Please enter a valid email address.</span
        >

        <div role="alert" class="rounded-lg border-1 border-white">
          <button
            (click)="this.submitted = false"
            class="btn btn-xs btn-ghost flex items-center rounded-full"
          >
            <span class="material-symbols-outlined">close</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div
    *ngIf="this.sendEmailResponse"
    class="alert bg-white bg-opacity-5 mt-8 border-0 flex items-center justify-between text-slate-400 text-sm"
  >
    <span [innerHTML]="this.sendEmailResponse"> </span>
    <button class="text-white" (click)="this.sendEmailResponse = undefined">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="w-4 h-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
</div>
