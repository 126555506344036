import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  router = inject(Router);
  authService = inject(MsalService);
  broadcastService = inject(MsalBroadcastService);
  
  constructor() { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        switchMap(() => {
          if(this.authService.instance.getAllAccounts().length === 0) {
            this.router.navigate(['/login']);
            return of(false);
          }
          return of(true);
        })
      )
  }
}

