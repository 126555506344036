import { Routes } from '@angular/router';
import { AdminComponent } from './pages/admin/admin.component';
import { DataFactoryComponent } from './pages/admin/customer/data-factory/data-factory.component';
import { FabricComponent } from './pages/admin/customer/fabric/fabric.component';
import { RoleGuard } from './shared/guards/role.guard';
import { MsalGuard } from '@azure/msal-angular';

export const adminRoutes: Routes = [
  {
    path: 'admin',
    loadComponent: () =>
      import('./pages/admin/admin.component').then((m) => m.AdminComponent),
    data: { roles: ['Admin.ReadWrite'] },
    canActivate: [RoleGuard, MsalGuard],
    children: [
      {
        path: 'fabric',
        loadComponent: () =>
          import('./pages/admin/customer/fabric/fabric.component').then(
            (m) => m.FabricComponent
          ),
      },
      {
        path: 'data-factory',
        loadComponent: () =>
          import(
            './pages/admin/customer/data-factory/data-factory.component'
          ).then((m) => m.DataFactoryComponent),
      },
      {
        path: 'environments',
        loadComponent: () =>
          import(
            './pages/admin/customer/environments/environments.component'
          ).then((m) => m.EnvironmentsComponent),
      },
      {
        path: 'infrastructure',
        loadComponent: () =>
          import(
            './pages/admin/customer/infrastructure/infrastructure.component'
          ).then((m) => m.InfrastructureComponent),
      },
      {
        path: 'users',
        loadComponent: () =>
          import('./pages/admin/customer/users/users.component').then(
            (m) => m.UsersComponent
          ),
      },
      // Add more admin routes here
    ],
  },
];
