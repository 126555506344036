@if( this.loadingReports$ | async; as loadingReports) {
<div class="w-full h-full flex items-center justify-center">
  <app-loading></app-loading>
</div>
} @else {
<div
  class="text-slate-50 flex flex-col p-8 xl:gap-y-4 h-screen min-h-0 overflow-y-auto"
>
  @if((this.availableWorkspaces$ | async)?.length === 0) {
  <div class="flex items-start justify-start h-full">
    <div class="card flex flex-col items-center justify-center gap-8 p-8">
      <p class="text-slate-100 text-center mb-8">
        It seems you don't have access to any workspaces.
      </p>
      <button
        class="px-8 py-4 bg-primary text-white rounded-lg"
        (click)="goToContact()"
      >
        Please Contact Us
      </button>
    </div>
  </div>
  } @else {

  <app-workspace-selector></app-workspace-selector>
  <h2 class="text-white leading-6 my-3 xl:my-4 ml-2">Your Dashboards</h2>

  @if(this.availableReports.length === 0) {
  <p
    class="ml-2 mb-2 xl:mb-8 text-slate-400 text-sm xl:text-base leading-5 xl:leading-6 tracking-normal w-2/3"
  >
    It seems like you have not bought any dashboards yet. Check out the
    available dashboards below.
  </p>
  } @else {

  <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
    @for(r of (this.availableReports$ |async); track r.id) {
    <app-report-card
      *ngIf="this.getProductFromReport(r) as p"
      [report]="r"
      [product]="p"
    ></app-report-card>
    <!-- <div (click)="setActiveReport(r.id)" class="card px-4 py-2 flex items-center justify-center h-[90Spx] border-b-4 border-transparent hover:border-b-4 hover:border-b-blue-300 hover:cursor-pointer">
            <h4 class="text-white text-center">{{ r.name }}</h4>
          </div> -->
    }
  </div>
  } @if(this.notBougthProducts.length > 0) {
  <div class="flex items-center gap-x-2 ml-2 my-3 xl:my-4">
    <h2 *ngIf="this.availableReports.length > 0">Also</h2>

    <h2 class="text-white leading-6 inline">available</h2>

    <h2 *ngIf="this.availableReports.length === 0">Dashboards</h2>
  </div>

  <p
    class="ml-2 mb-4 xl:mb-8 text-slate-400 text-sm xl:text-base leading-5 xl:leading-6 tracking-normal w-2/3"
  >
    Each dashboard provides a central view on a specific cloud category.
    Multiple data sources are intertwined in order to provide a full view for
    that category without the need for checking multiple dashboards that provide
    a part of the required view. Click on a dashboard to learn more.
  </p>
  <div class="flex flex-wrap gap-4 items-center justify-start">
    @for(product of this.notBoughtProducts$ | async; track product.id) {
    <div
      (click)="goToProduct(product.name)"
      class="card flex flex-col items-center justify-center h-[100px] w-[150px] px-2.5 py-1 hover:cursor-pointer border-b-4 border-transparent hover:border-b-4 hover:border-b-blue-300"
    >
      <span class="material-symbols-outlined text-[32px] mb-4">{{
        product.material_icon
      }}</span>
      <h5 class="text-xs text-slate-300">{{ product.name }}</h5>
    </div>
    }
  </div>
  } }
</div>
}
