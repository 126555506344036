<dialog
  id="confirmation_dialog"
  class="modal text-slate-50"
  [open]="this.dialogOpen"
>
  <div
    class="flex flex-col gap-y-4 items-center  w-[33vw] max-w-[33vw] bg-darkbg bg-opacity-95 rounded-lg px-8 py-8  relative"
  >
    <span class="material-symbols-outlined md-64">warning</span>
    <h3 class="text-2xl">{{ action | titlecase }}</h3>
    <span> Are you sure you want to {{ this.action }}? </span>
    <div class="flex flex-row items-center justify-around gap-x-4">
      <button
        (click)="onConfirm()"
        class="rounded border-0 bg-bo_primary py-2 px-6 text-lg text-white hover:bg-opacity-85 focus:outline-none"
      >
        Confirm
      </button>
      <button
        (click)="onCancel()"
        class="rounded border-0 bg-accent py-2 px-6 text-lg text-white hover:bg-opacity-85 focus:outline-none"
      >
        Cancel
      </button>
    </div>
    <form method="dialog">
      <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" (click)="onCancel()">
        ✕
      </button>
    </form>
  </div>
</dialog>
