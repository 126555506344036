import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';


export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'discover',
        loadComponent: () => import('./pages/discover/discover.component').then(m => m.DiscoverComponent),
    },
    {
        path: 'contact',
        component: FeedbackComponent
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard, MsalGuard],
    },
    {
        path: 'admin',
        loadComponent: () => import('./pages/admin/admin.component').then(m => m.AdminComponent),
        data: { roles: ['Admin.ReadWrite'] },
        canActivate: [RoleGuard, MsalGuard],
    },
    {
        path: 'report',
        loadComponent: () => import('./pages/report/report.component').then(m => m.ReportComponent),
        canActivate: [AuthGuard, MsalGuard],
    },
    {
        path: 'product/:name',
        loadComponent: () => import('./pages/product-detail/product-detail.component').then(m => m.ProductDetailComponent),
    },
    {
        path: '**',
        redirectTo: 'home'
    }
]
