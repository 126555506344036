import { Component, Input, OnInit, inject } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { PowerBiApi } from '../../../shared/models/power-bi-models';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { products } from '../../../shared/data/product-data';
import { BlueLake } from '../../../shared/models/bluelake-models';
import { CommonModule } from '@angular/common';
import { ReportIconComponent } from '../report-icon/report-icon.component';

@Component({
  selector: 'app-reports-icon-list',
  standalone: true,
  imports: [CommonModule, ReportIconComponent],
  templateUrl: './reports-icon-list.component.html',
  styleUrl: './reports-icon-list.component.scss'
})
export class ReportsIconListComponent  implements OnInit {

  private readonly _destroying$ = new Subject<void>();

  // hard coded in /shared/data/product-data.ts
  availableProducts: BlueLake.Product[] = products;

  reports : PowerBiApi.Report[] | undefined;
  activeReport : PowerBiApi.Report | undefined;

  applicationStateService = inject(ApplicationStateService);

  constructor() { }

  ngOnInit(): void {
    this.applicationStateService.availableReports$.pipe(takeUntil(this._destroying$)).subscribe({
      next: (reports) => {
        this.reports = reports;
      }, error: (error) => {
        console.warn('Error getting reports', error);
      }
    });
    this.applicationStateService.activeReport$.pipe(takeUntil(this._destroying$)).subscribe({
      next: (report) => {
        this.activeReport = report;
      }, error: (error) => {
        console.warn('Error getting active report', error);
      }
    });
  }

  getProductFromReport(report: PowerBiApi.Report): BlueLake.Product | undefined {
    return this.availableProducts.find(p => p.name.toLocaleLowerCase() === report.name.toLocaleLowerCase());
  }

  setActiveReport(reportId: string) {
    this.applicationStateService.setActiveReport(reportId);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

}
