import { Component, Input, OnInit, inject } from '@angular/core';
import { PowerBiApi } from '../../../shared/models/power-bi-models';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { Router } from '@angular/router';
import { BlueOcean } from '../../../shared/models/bluelake-models';
import { products } from '../../../shared/data/product-data';
import { CommonModule } from '@angular/common';
import { query } from '@angular/animations';

@Component({
  selector: 'app-report-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './report-card.component.html',
  styleUrl: './report-card.component.scss',
})
export class ReportCardComponent implements OnInit {
  @Input() report!: PowerBiApi.Report;
  @Input() product!: BlueOcean.Product;

  applicationStateService = inject(ApplicationStateService);
  router = inject(Router);

  constructor() {}

  ngOnInit(): void {}

  setActiveReport(reportId: string) {
    this.applicationStateService.setActiveReport(reportId);
    this.router.navigate(['report']);
  }

  goToProductInfo(event: Event) {
    event.stopPropagation();
    this.router.navigate(['/product', this.product.shortName]);
  }

  ngOnDestroy(): void {}
}
