<aside
  class="min-h-0 sidebar fixed flex flex-col top-0 bottom-0 lg:left-0 py-2 text-center text-slate-50 bg-[url('assets/gradient_background_sidenav.svg')] bg-cover ease-in-out duration-300 px-2"
  [ngClass]="{ 'w-[250px]': !this.collapsed, 'w-[100px]': this.collapsed }"
>
  <!-- <div class="h-8">

  </div> -->
  @if(this.collapsed) {
  <div class="p-1 xl:p-2.5 flex items-center justify-center">
    <a
      [routerLink]="(this.authenticated$ | async) ? 'home' : 'discover'"
      class="ml-2"
    >
      <img
        src="../../../../assets/images/blueocean_logo_small.png"
        alt="logo"
        class="h-6 xl:h-8"
      />
    </a>
  </div>
  } @else {
  <div class="p-2.5">
    <a
      [routerLink]="(this.authenticated$ | async) ? 'home' : 'discover'"
      class=""
    >
      <img
        src="../../../../assets/images/blueocean_logo_large.png"
        alt="logo"
        class="object-contain h-full px-2"
      />
    </a>
  </div>

  }
  <div class="bg-gray-600 h-[1px] my-2 mx-2"></div>

  <!-- <div
    class="p-1 lg:p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer bg-white bg-opacity-15 text-white"
  >
    <span class="material-symbols-outlined"> search </span>
    <input
      type="text"
      placeholder="Search"
      class="text-[15px] ml-4 w-full bg-transparent focus:outline-none"
    />
  </div> -->

  <!-- HOME -->
  <app-nav-item
    *ngIf="this.authenticated$ | async"
    [collapsed]="this.collapsed"
    routerLink="home"
    icon="home"
    label="home"
  />
  <!-- Discover -->
  <app-nav-item
    [collapsed]="this.collapsed"
    routerLink="discover"
    icon="dashboard_customize"
    label="Discover"
  />
  <!-- ADMIN -->
  <app-nav-item
    *ngIf="this.isAdmin$ | async"
    [collapsed]="this.collapsed"
    routerLink="admin"
    icon="admin_panel_settings"
    label="Admin Center"
  />

  <!-- line -->
  <div class="my-2 mx-2 bg-gray-600 h-[1px]"></div>

  @if(this.collapsed && this.route !== '/login') {
  <!-- Report icons -->
  <div class="overlfow-y-auto min-h-0">
    <app-reports-icon-list></app-reports-icon-list>
  </div>
  }
  <div class="flex-1"></div>
  <!-- Feedback -->
  <app-nav-item
    [collapsed]="this.collapsed"
    routerLink="contact"
    icon="chat"
    label="Contact"
  />
  <app-nav-item
    *ngIf="this.authenticated$ | async"
    [collapsed]="this.collapsed"
    (click)="this.confirmDialogOpen = true"
    icon="logout"
    label="Logout"
  />
  <div class="m-2 bg-gray-600 h-[1px]"></div>
  <!-- User  info-->
  @if(this.authenticated$ | async) {
  <app-user-profile-nav-item
    [collapsed]="this.collapsed"
  ></app-user-profile-nav-item>
  } @else {
  <!-- Login -->
  <app-nav-item
    [collapsed]="this.collapsed"
    routerLink="login"
    icon="login"
    label="login"
  />
  }
</aside>

<app-confirm-dialog
  [dialogOpen]="this.confirmDialogOpen"
  action="log out"
  (confirm)="this.logout()"
  (cancel)="this.confirmDialogOpen = false"
></app-confirm-dialog>
