import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { MsGraph } from '../../../shared/models/ms-graph-models';
import { MsGraphService } from '../../../shared/services/ms-graph.service';

@Component({
  selector: 'app-user-profile-nav-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-profile-nav-item.component.html',
  styleUrl: './user-profile-nav-item.component.scss',
})
export class UserProfileNavItemComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  @Input() collapsed = false;

  authService = inject(MsalService);
  applicationStateService = inject(ApplicationStateService);
  msGraphService = inject(MsGraphService);

  me$: Observable<MsGraph.GetMeResponse> | undefined;
  myPhoto$: Observable<any> | undefined;


  constructor() {}

  ngOnInit(): void {
    this.applicationStateService.isAuthenticated$
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (isAuthenticated) => {
          if (isAuthenticated) {
            this.me$ = this.msGraphService.getMe();
            this.myPhoto$ = this.msGraphService.getMyPhoto();
          }
        },
        error: (error) => {
          console.warn('Error getting authenticated state', error);
        },
      });
  }

  logout() {
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
