<div class="flex flex-col items-start justify-center gap-y-2 mb-3 xl:mb-4">
  <!-- <p *ngIf="this.showChangeWorkspaceTip" class="ml-2 mb-1.5 text-slate-400 text-sm xl:text-base leading-5 xl:leading-6 tracking-normal">Select your required workspace and start gaining insights!</p> -->
  <div class="dropdown dropdown-right">
    <div
      tabindex="0"
      role="button"
      class="px-4 py-2 rounded-lg hover:bg-opacity-10 mr-1 bg-white bg-opacity-5"
    >
      <div class="w-full h-full flex flex-row items-center gap-x-4">
        <h2 class="text-slate-300 leading-6 ml-2">Active Workspace:</h2>
        <h4 class="text-slate-50">
          {{ (this.activeWorkspace$ | async)?.displayName ?? "" }}
        </h4>
        <span
          *ngIf="this.showChangeWorkspaceTip"
          class="material-symbols-outlined"
        >
          arrow_drop_down
        </span>
      </div>
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content bg-[#0a2848]/95 text-base-100 rounded-lg z-[1] w-100 p-2"
    >
      <input
        *ngIf="this.showSearchField"
        placeholder="Search"
        #input
        type="text"
        [ngModel]="this.searchQuery()"
        (ngModelChange)="this.onSearchUpdated($event)"
        class="input input-sm md:input-sm text-slate-50 bg-white bg-opacity-5 rounded-lg w-full min-w-64 mb-1 lg:mb-1"
      />
      <div
        class="text-lg flex flex-col items-start justify-start min-h-0 max-h-64 overflow-y-auto max-w-full"
      >
        @for (w of items() | async; track w.id) {
          <button
            class="text-start my-1 px-2 py-1 hover:bg-white w-full rounded-lg hover:bg-opacity-5"
            [ngClass]="{
              'bg-white bg-opacity-5 ':
                (this.activeWorkspace$ | async)?.displayName === w.displayName,
            }"
            (click)="setActiveWorkspace(w.id)"
          >
            {{ w.displayName }}
          </button>
        }
      </div>
    </ul>
  </div>
</div>
