<!-- HOME -->
<button
  (click)="onClick($event)"
  [routerLink]="routerLink"
  [queryParams]="queryParams"
  routerLinkActive="active-button"
  [routerLinkActiveOptions]="{ exact: true }"
  class="btn-ghost px-2.5 py-1 flex items-center rounded text-base-100 w-full hover:bg-white hover:bg-opacity-5 border-r-transparent border-r-4"
  [ngClass]="{
    'justify-start flex-row': !this.collapsed,
    'justify-center flex-col ': this.collapsed,
  }"
>
  <span class="material-symbols-outlined"> {{ this.icon }} </span>
  <span *ngIf="!this.collapsed" class="text-[12px] ml-4 text-gray-200">
    {{ this.label }}</span
  >
</button>
