import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  http = inject(HttpClient);

  constructor() {}

  sendMail(title: string, email: string, type: string, message: string) : Observable<any> {
    const subject = `${type} - ${title}`;
    const content = `Sent by: ${email}\n\n${message}`;
    return this.http.post('/api/sendEmail', {
      subject: subject,
      text: content,
    })
  }
}
