import { CommonModule, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Router, RouterModule, RoutesRecognized } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Subject, filter, map, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../../../environments/environment.development';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { MsGraphService } from '../../../shared/services/ms-graph.service';
import { ReportsIconListComponent } from '../../reports/reports-icon-list/reports-icon-list.component';
import { NavItemComponent } from '../nav-item/nav-item.component';
import { UserProfileNavItemComponent } from '../user-profile-nav-item/user-profile-nav-item.component';

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NavItemComponent,
    UserProfileNavItemComponent,
    ConfirmDialogComponent,
    ReportsIconListComponent
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent implements OnInit {


  @Input() width: string | undefined = '264px';

  confirmDialogOpen = false;
  route: string | undefined;

  private readonly _destroying$ = new Subject<void>();

  @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  collapsed = false;

  authService = inject(MsalService);
  msGraphService = inject(MsGraphService);
  router = inject(Router);
  location = inject(Location);
  applicationStateService = inject(ApplicationStateService);

  authenticated$ = this.applicationStateService.isAuthenticated$;
  activeAccount$ = this.applicationStateService.activeAccount$;

  isAdmin$ = this.activeAccount$.pipe(
    map((account) => {
      return account?.idTokenClaims?.roles ?? [];
    }),
    map((roles) => roles.includes('Admin.ReadWrite'))
  );
  isHealthGuard$ = this.activeAccount$.pipe(
    map((account) => {
      return account?.idTokenClaims?.roles ?? [];
    }),
    map((roles) => roles.includes('HealthGuard.ReadWrite'))
  );

  constructor() {}

  ngOnInit() {
    console.log(this.location.path());
    this.route = this.location.path();
    this.setCollapsed(this.location.path());
    this.router.events
      .pipe(
        takeUntil(this._destroying$),
        filter((ev) => ev instanceof RoutesRecognized)
      )
      .subscribe({
        next: (ev: any) => {
          const route = ev.url;
          this.route = route;
          this.setCollapsed(route);
        },
        error: (error) => {
          console.warn('Error changing routes', error);
        },
      });
  }

  setCollapsed(route: string) {
    this.collapsed = route === '/report' || route === '/login' || route === '/health-guard'; // TODO  || route === 'health-guard'
    this.collapsedChange.emit(this.collapsed);
  }

  // TODO - Implement switchAccount method
  switchAccount() {
    this.authService.instance.loginPopup({
      authority: environment.msalConfig.auth.authority,
      prompt: 'select_account',
      scopes: [...environment.apiConfig.scopes, 'user.read'],
    });

    this.authService
      .loginPopup({
        authority: environment.msalConfig.auth.authority,
        prompt: 'select_account',
        scopes: [...environment.apiConfig.scopes, 'user.read'],
      })
      .pipe()
      .subscribe({
        next: (response: AuthenticationResult) => {
          console.log('response', response);
          this.authService.instance.setActiveAccount(response.account);
        },
        error: (error) => {
          console.warn('error switching accounts', error);
        },
      });
  }

  logout() {
    this.authService.logoutRedirect();
    this.confirmDialogOpen = false;
  }

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
