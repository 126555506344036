<div class="flex items-center justify-center h-screen min-h-0 w-full">
  <div class="card bg-white bg-opacity-15 py-4  lg:px-16 lg:py-8 w-1/3 xl:w-1/4">
    <div class="grid gap-y-8 mb-3 py-4 px-16">
      <figure>
        <img
          src="../../../assets/images/dexmach_brain.png"
          alt="DexMach"
          class="h-8"
        />
      </figure>
      <figure>
        <img
          src="../../../assets/images/blueocean_dexmach_product.png"
          alt="BlueOcean DexMach Product"
        />
      </figure>
    </div>

    <div class="card-body">
      <div class="card-actions justify-center">
        <button
          (click)="login()"
          type="submit"
          class="px-4 py-2 w-full bg-bo_primary text-white font-thin rounded-lg hover:cursor-pointer hover:bg-opacity-75"
        >
          Login
        </button>
        <button
          routerLink="/discover"
          type="submit"
          class="px-4 py-2 w-full bg-darkbg text-white font-thin rounded-lg hover:cursor-pointer hover:bg-opacity-75"
        >
          Discover
        </button>
      </div>
    </div>
  </div>
</div>
