import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Params, RouterModule } from '@angular/router';

@Component({
  selector: 'app-nav-item',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './nav-item.component.html',
  styleUrl: './nav-item.component.scss'
})
export class NavItemComponent { 


  @Input() collapsed = false;
  @Input() icon: string | undefined;
  @Input() routerLink: string | undefined;
  @Input() queryParams: Params | undefined;
  @Input() label: string | undefined;
  @Input() tooltip: string | undefined;

  @Output() click = new EventEmitter<Event>();

  onClick(event: Event) {
    this.click.emit(event);
  }

}
