import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { of, Subject, takeUntil } from 'rxjs';
import { ReportCardComponent } from '../../features/reports/report-card/report-card.component';
import { LoadingComponent } from '../../shared/components/loading/loading.component';
import { products } from '../../shared/data/product-data';
import { BlueLake } from '../../shared/models/bluelake-models';
import { PowerBiApi } from '../../shared/models/power-bi-models';
import { ApplicationStateService } from '../../shared/services/application-state.service';
import { ReportComponent } from '../report/report.component';
import { WorkspaceSelectorComponent } from '../../features/workspaces/workspace-selector/workspace-selector.component';
import { ReportIconComponent } from '../../features/reports/report-icon/report-icon.component';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    ReportCardComponent,
    RouterModule,
    ReportComponent,
    WorkspaceSelectorComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  private readonly _destroying$ = new Subject<void>();

  availableProducts: BlueLake.Product[] = products;

  applicationStateService = inject(ApplicationStateService);
  router = inject(Router);
  authService = inject(MsalService);

  loadingReports$ = this.applicationStateService.loadingReports$;
  activeWorkspace$ = this.applicationStateService.activeWorkspace$;
  availableWorkspaces$ = this.applicationStateService.availableWorkspaces$;
  availableReports$ = this.applicationStateService.availableReports$;
  notBoughtProducts$ =
    this.applicationStateService.alsoAvailableNotBoughtProducts$;
  notBougthProducts: BlueLake.Product[] = [];
  availableReports: PowerBiApi.Report[] = [];

  constructor() {}

  ngOnInit(): void {
    this.notBoughtProducts$
      .pipe(takeUntil(this._destroying$))
      .subscribe((products) => {
        this.notBougthProducts = products;
      });
    this.availableReports$
      .pipe(takeUntil(this._destroying$))
      .subscribe((reports) => {
        this.availableReports = reports;
        console.log(
          `available reports: ${reports.map((r) => r.name).join(', ')}`
        );
      });
    if (!this.authService.instance.getActiveAccount()) {
      this.applicationStateService.loadUserData();
    }
  }

  getProductFromReport(
    report: PowerBiApi.Report
  ): BlueLake.Product | undefined {
    return this.availableProducts.find(
      (product) =>
        product.name.toLocaleLowerCase() === report.name.toLocaleLowerCase()
    );
  }

  goToProduct(product: string) {
    this.router.navigate([`/product/${product}`]);
  }

  goToContact() {
    this.router.navigate(['/contact'])
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
