import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, take } from 'rxjs';
import { EmailService } from '../../shared/services/email.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-feedback',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss',
})
export class FeedbackComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  emailService = inject(EmailService);
  sanitizer = inject(DomSanitizer);

  fb = inject(FormBuilder);
  contactForm!: FormGroup;

  submitted = false;
  requiredErrors = true;
  emailError = false;
  sendEmailResponse: SafeHtml | undefined;

  types: string[] = ['Feedback', 'Issue', 'Quote', 'Other'];

  constructor() {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      title: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      type: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });

    this.contactForm.valueChanges.subscribe((value) => {
      this.requiredErrors =
        this.title.hasError('required') ||
        this.email.hasError('required') ||
        this.type.hasError('required') ||
        this.message.hasError('required');
      this.emailError =
        this.email.hasError('email') && !this.email.hasError('required');
    });
  }

  get title() {
    return this.contactForm.get('title')!;
  }

  get email() {
    return this.contactForm.get('email')!;
  }

  get type() {
    return this.contactForm.get('type')!;
  }

  get message() {
    return this.contactForm.get('message')!;
  }

  get controls() {
    return this.contactForm.controls;
  }

  submitForm() {
    this.submitted = true;
    if (this.contactForm.valid) {
      document.body.style.cursor = 'wait';
      this.emailService
        .sendMail(
          this.title.value,
          this.email.value,
          this.type.value,
          this.message.value,
        )
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            // console.log('response', response);
            this.sendEmailResponse =
              'Email sent successfully, we will get back to you soon.';
            this.contactForm.reset();
            this.submitted = false;
            document.body.style.cursor = 'default';
          },
          error: (error) => {
            console.log('error', error);
            if (error.status === 200) {
              this.sendEmailResponse =
                'Your feedback was successfully submitted, we will get back to you soon.';
            } else {
              this.sendEmailResponse = this.sanitizer.bypassSecurityTrustHtml(
                'Something went wrong while submitting your feedback, you can always directly reach out to us through <a href="mailto:blueocean@dexmach.com">blueocean@dexmach.com</a>',
              );
            }
            this.contactForm.reset();
            this.submitted = false;
            document.body.style.cursor = 'default';
          },
        });
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
