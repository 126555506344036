<button
  (click)="setActiveReport(report.id)"
  type="button"
  class="flex flex-col items-center justify-center rounded-l-lg w-full py-1  xl:py-2  hover:cursor-pointer"
  [ngClass]="{ active: this.report.name === this.activeReport?.name }"
>
  <!-- <img [src]="'../../../../assets/products/' + iconUrl" class="h-8" /> -->
   <span class="material-symbols-outlined">{{ materialIcon}}</span>
  <span class="text-[8px] text-slate-500"> {{ report.name }} </span>
</button>
