<div
  class="flex items-center my-1 relative py-1 px-2"
  [ngClass]="{
    'justify-between': !this.collapsed,
    'justify-center': this.collapsed
  }"
>
  <div class="avatar">
    <div class="w-8 rounded-full bg-bo_primary">
      <div class="flex items-center justify-center w-full h-full">
        {{ (me$ | async)?.displayName?.at(0) }}
      </div>
    </div>
  </div>
  <div
    *ngIf="!this.collapsed"
    class="flex flex-grow ml-2 flex-col items-start justify-center p-2"
  >
    <span class="text-sm text-gray-200">
      {{ (me$ | async)?.displayName }}
    </span>
    <span class="text-xs text-gray-400"> {{ (me$ | async)?.jobTitle }}</span>
  </div>
</div>
