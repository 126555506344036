<header *ngIf="this.showHeader" class="fixed top-0 left-0 w-full bg-primary z-50 h-16 flex items-center px-4">
  <div class="flex items-center justify-between p-2 w-full">
    <!-- Logo -->
    <a [routerLink]="(this.authenticated$ | async) ? 'home' : 'discover'">
      <img src="../../../../assets/images/blueocean_logo_small.png" alt="logo" class="h-6" />
    </a>

    <!-- Menu Toggle Button -->
    <button class="flex items-center text-base-100  " (click)="this.showMobileMenu = !this.showMobileMenu">
      <span class="material-symbols-outlined">{{ this.showMobileMenu ? 'close' : 'menu' }}</span>
    </button>
  </div>
</header>
<nav *ngIf="this.showMobileMenu"    
  class="fixed right-2 top-16 w-[200px] bg-primary border border-base-100/50 rounded p-2 transition z-100">
  <ul class="menu w-full rounded-box">
    <app-nav-item *ngIf="this.authenticated$ | async" routerLink="home" icon="home" label="Home" />
    <app-nav-item routerLink="discover" icon="dashboard_customize" label="Discover" />
    <app-nav-item *ngIf="this.isAdmin$ | async" routerLink="admin" icon="admin_panel_settings" label="Admin Center" />
    <app-nav-item *ngIf="(this.isAdmin$ | async) || (this.isHealthGuard$ | async)" routerLink="health-guard"
      icon="shield_with_heart" label="Health Guard" />
    <app-nav-item routerLink="contact" icon="chat" label="Contact" />
    <app-nav-item *ngIf="this.authenticated$ | async" (click)="this.confirmDialogOpen = true" icon="logout"
      label="Logout" />
    <app-nav-item *ngIf="!(this.authenticated$ | async)" routerLink="login" icon="login" label="Login" />
  </ul>
</nav>


<aside *ngIf="!this.showHeader"
  class="min-h-0 sidebar fixed flex flex-col top-0 bottom-0 lg:left-0 py-2 text-center text-primary-content bg-primary bg-cover ease-in-out duration-300"
  [ngClass]="{ 'w-[250px]': !this.collapsed, 'w-[80px]': this.collapsed }">
  <!-- <div class="h-8">

  </div> -->
  @if (this.collapsed) {
  <a [routerLink]="(this.authenticated$ | async) ? 'home' : 'discover'"
    class="p-1 flex items-center justify-center w-full">
    <img src="../../../../assets/images/blueocean_logo_small.png" alt="logo" class="h-6" />
  </a>
  } @else {
  <div class="p-1">
    <a [routerLink]="(this.authenticated$ | async) ? 'home' : 'discover'" class="">
      <img src="../../../../assets/images/blueocean_logo_large.png" alt="logo" class="object-contain pr-4 py-4 px-2" />
    </a>
  </div>
  }
  <div class="bg-neutral h-[1px] mb-1 mx-2" [ngClass]="{ 'mt-2': this.collapsed }"></div>

  <!-- <div
    class="p-1 lg:p-2.5 flex items-center rounded-md px-4 duration-300 cursor-pointer bg-white bg-opacity-5 text-white"
  >
    <span class="material-symbols-outlined"> search </span>
    <input
      type="text"
      placeholder="Search"
      class="text-[15px] ml-4 w-full bg-transparent focus:outline-none"
    />
  </div> -->
  <div class="grid gap-y-1">
    <!-- HOME -->
    <app-nav-item *ngIf="this.authenticated$ | async" [collapsed]="this.collapsed" routerLink="home" icon="home"
      label="home" />
    <!-- Discover -->
    <app-nav-item [collapsed]="this.collapsed" routerLink="discover" icon="dashboard_customize" label="Discover" />
    <!-- ADMIN -->
    <app-nav-item *ngIf="this.isAdmin$ | async" [collapsed]="this.collapsed" routerLink="admin"
      icon="admin_panel_settings" label="Admin Center" />
    <app-nav-item *ngIf="(this.isAdmin$ | async) || (this.isHealthGuard$ | async)" [collapsed]="this.collapsed"
      routerLink="health-guard" icon="shield_with_heart" label="Health Guard" />
  </div>

  <!-- line -->
  <div class="mt-1 mx-2 bg-neutral h-[1px]"></div>

  @if (this.collapsed && this.route === "/report") {
  <!-- Report icons -->
  <div class="overlfow-y-auto min-h-0">
    <app-reports-icon-list></app-reports-icon-list>
  </div>
  }
  <div class="flex-1"></div>
  <!-- Feedback -->
  <app-nav-item [collapsed]="this.collapsed" routerLink="contact" icon="chat" label="Contact" />
  <app-nav-item *ngIf="this.authenticated$ | async" [collapsed]="this.collapsed" (click)="this.confirmDialogOpen = true"
    icon="logout" label="Logout" />
  <div class="m-2 bg-neutral h-[1px]"></div>
  <!-- User  info-->
  @if (this.authenticated$ | async) {
  <app-user-profile-nav-item [collapsed]="this.collapsed"></app-user-profile-nav-item>
  } @else {
  <!-- Login -->
  <app-nav-item [collapsed]="this.collapsed" routerLink="login" icon="login" label="login" />
  }
</aside>

<app-confirm-dialog [dialogOpen]="this.confirmDialogOpen" action="log out" (confirm)="this.logout()"
  (cancel)="this.confirmDialogOpen = false"></app-confirm-dialog>