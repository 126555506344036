<div
  (click)="setActiveReport(this.report.id)"
  class="card px-4 py-4 xl:py-4 flex flex-col items-center justify-center border-b-4 border-transparent hover:border-b-4 hover:border-b-blue-300 hover:cursor-pointer"
>
  <div class="w-full flex flex-row items-center justify-between px-1">
    <h4 class="text-white text-center">{{ this.report.name }}</h4>
    <button (click)="goToProductInfo($event)">
      <span class="material-symbols-outlined hover:text-slate-400"> info </span>
    </button>
  </div>
  <div class="w-full px-1 lg:px-2 h-[70px] xl:h-[150px]">
    <div class="flex items-center justify-start gap-x-4 xl:gap-x-8 h-full">
      <!-- <img
        [src]="'../../../../assets/products/' + this.product.iconUrl"
        alt="{{ product.name }}"
        class="p-1 md:rounded-none rounded-full h-[32px] sm:h-[48px] xl:h-[78px]"
      /> -->
      <span
        class="material-symbols-outlined text-white text-[32px] sm:text-[48px] xl:text-[78px]"
      >
        {{ this.product.material_icon }}
      </span>
      <div
        class="flex items-center justify-start lg:justify-center h-[80px] overflow-y-auto"
      >
        <span
          class="text-slate-400 text-xs xl:text-sm leading-4 tracking-tight"
        >
          {{ this.product.shortDescription }}
        </span>
      </div>
    </div>
  </div>
</div>
