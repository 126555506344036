<!-- HOME -->
<button
  (click)="onClick($event)"
  [routerLink]="routerLink"
  [queryParams]="queryParams"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{ exact: true }"
  class="px-2.5 py-1 xl:p-2.5 flex items-center rounded-l-lg text-white w-full my-1"
  [ngClass]="{
    'justify-start flex-row': !this.collapsed,
    'justify-center flex-col ': this.collapsed
  }"
>
  <span class="material-symbols-outlined "> {{ this.icon }} </span>
  <span *ngIf="!this.collapsed" class="text-[15px] ml-4 text-gray-200">
    {{ this.label }}</span
  >
</button>
