<!-- <app-sidenav *ngIf="this.loginDisplay" [width]="'350px'"></app-sidenav>
<main class="h-screen" [ngClass]="{ 'ml-[350px]': this.loginDisplay}">
  @if (!isIframe) {
  <router-outlet></router-outlet>
  }
</main> -->
<app-sidenav
  [width]="'200px'"
  (collapsedChange)="this.collapsed = $event"
></app-sidenav>
<main
  class="h-screen"
  [ngClass]="{ 'ml-[250px]': !this.collapsed, 'ml-[80px]': this.collapsed }"
>
  @if (!isIframe) {
  <router-outlet></router-outlet>
  }
</main>
<!-- <main class="h-screen">
  @if (!isIframe) {
  <router-outlet></router-outlet>
  }
</main> -->
