<div class="flex flex-col items-center justify-center gap-y-1 xl:gap-y-4 overflow-y-auto">
  @for(report of this.reports; track report.id) {
  <ng-container *ngIf="this.getProductFromReport(report) as product">
    <app-report-icon [report]="report" [materialIcon]="product.material_icon"></app-report-icon>
    <!-- <button
      (click)="setActiveReport(report.id)"
      type="button"
      class="flex flex-col items-center justify-center hover:cursor-pointer hover:bg-white hover:bg-opacity-15 rounded-lg w-full py-2 gap-y-2"
      [ngClass]="{ active: this.report.name === this.activeReport?.name }"
    >
      <img
        [src]="'../../../../assets/products/' + product.iconUrl"
        class="h-8"
      />
      <span class="text-[8px] text-slate-500"> {{ report.name }} </span>
    </button> -->
  </ng-container>
  }
</div>
