import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { Subject, map, takeUntil } from 'rxjs';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-workspace-selector',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './workspace-selector.component.html',
  styleUrl: './workspace-selector.component.scss',
})
export class WorkspaceSelectorComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  applicationStateService = inject(ApplicationStateService);
  activeWorkspace$ = this.applicationStateService.activeWorkspace$;
  availableWorkspaces$ = this.applicationStateService.availableWorkspaces$;

  showSearchField = false;
  showChangeWorkspaceTip = false;

  searchQuery = signal<string>('');
  items = computed(() => {
    const sq = this.searchQuery();
    return this.availableWorkspaces$.pipe(
      map((workspaces) => {
        if (sq === '') return workspaces;
        const result = workspaces.filter((workspace) =>
          workspace.name.toLowerCase().includes(sq.toLowerCase()),
        );
        // console.log(result);
        return result;
      }),
    );
  });

  constructor() {}

  ngOnInit(): void {
    this.availableWorkspaces$.pipe(takeUntil(this._destroying$)).subscribe({
      next: (workspaces) => {
        this.showSearchField = workspaces.length > 5;
        this.showChangeWorkspaceTip = workspaces.length > 1;
      },
    });
  }

  onSearchUpdated(sq: string) {
    this.searchQuery.set(sq);
  }

  setActiveWorkspace(workspaceId: string) {
    this.applicationStateService.setActiveWorkspace(workspaceId);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
