import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { MsGraph } from '../models/ms-graph-models';

@Injectable({
  providedIn: 'root',
})
export class MsGraphService {
  http = inject(HttpClient);

  constructor() {}

  getMe(): Observable<MsGraph.GetMeResponse> {
    return this.http.get('https://graph.microsoft.com/v1.0/me').pipe(
      map((response: any) => {
        return response;
      }),
      // tap(console.log)
    );
  }

  getMyPhoto(): Observable<any> {
    return this.http
      .get('https://graph.microsoft.com/v1.0/me/photo/$value')
      .pipe(
        catchError((error: any) => {
          console.warn('logged in user has no photo', error);
          return of(undefined);
        }),
        map((response: any) => {
          return response;
        }),
      );
  }
}
