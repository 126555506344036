import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  InteractionType,
  PopupRequest,
  AuthenticationResult,
  InteractionStatus,
} from '@azure/msal-browser';
import { Subject, filter, takeUntil, tap } from 'rxjs';
import { ApplicationStateService } from '../../shared/services/application-state.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  loginDisplay = false;

  authService = inject(MsalService);
  broadcastService = inject(MsalBroadcastService);
  router = inject(Router);
  applicationStateService = inject(ApplicationStateService);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}

  ngOnInit(): void {
    // this.broadcastService.inProgress$
    //   .pipe(
    //     filter(
    //       (status: InteractionStatus) => status === InteractionStatus.None
    //     ),
    //     takeUntil(this._destroying$)
    //   )
    //   .subscribe(() => {
    //     this.setLoginDisplay();
    //     if (this.loginDisplay) {
    //       this.router.navigate(['/profile/home']);
    //     }
    //   });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.applicationStateService.loadUserData();
            this.navigateToHome();
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.applicationStateService.loadUserData();
            this.navigateToHome();
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            console.log(response.accessToken);
            this.applicationStateService.loadUserData();
            this.navigateToHome();
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            console.log('logincomponent active account', response.account);
            this.applicationStateService.loadUserData();
            this.navigateToHome();
          });
      }
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  navigateToHome() {
    this.router.navigate(['/home']);
  }
}
