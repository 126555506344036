import { Component, Input, OnInit, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ApplicationStateService } from '../../../shared/services/application-state.service';
import { PowerBiApi } from '../../../shared/models/power-bi-models';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-report-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './report-icon.component.html',
  styleUrl: './report-icon.component.scss',
})
export class ReportIconComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  @Input() report!: PowerBiApi.Report;
  // @Input() iconUrl!: string;
  @Input() materialIcon: string | undefined;
  @Input() collapsed: boolean = false;
  activeReport: PowerBiApi.Report | undefined;

  applicationStateService = inject(ApplicationStateService);

  constructor() {}

  ngOnInit(): void {
    this.applicationStateService.activeReport$
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (report) => {
          this.activeReport = report;
        },
        error: (error) => {
          console.warn('Error getting active report', error);
        },
      });
  }

  setActiveReport(reportId: string) {
    this.applicationStateService.setActiveReport(reportId);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
