<button
  (click)="setActiveReport(report.id)"
  type="button"
  class="flex flex-col items-center justify-center rounded w-full px-1"
  [ngClass]="{ active: this.report.name === this.activeReport?.name }"
>
  <!-- <img [src]="'../../../../assets/products/' + iconUrl" class="h-8" /> -->
  <span class="material-symbols-outlined">{{ materialIcon }}</span>
  <span class="text-[0.5rem] font-thin text-base-300 truncate">
    {{ report.name }}
  </span>
</button>
